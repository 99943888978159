import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import selectmainlogo from '../../assets/images/select/selectmainlogo.svg';
import selectindianotc from '../../assets/images/select/selectindianotc.svg';
import addimg from '../../assets/images/select/addimg.svg';
import './selectPage.scss';
import { GetAllApps } from '../../services/actionservice';
import { MainContext } from '../../components/Context/context';
import { GetAllPairs, GetpairsOfQuote } from '../../services/actionservice';
import {GetAppData} from '../../services/loginservice';

const addtermial = 
    {
        id:'addterminal',
        color_logo:addimg,
        text:'Find New Terminals',
        app_code:''
    }


function SelectPage() {
    const {selectedurl, setselectedurl, appSelectList, setappSelectList,
        selectedPair, setselectedPair, coin, setcoin, pairsList, setpairsList, allpairs, setallpairs} = useContext(MainContext)
        const [isLoading, setisLoading] = useState({})
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
       
    }, [])

    useEffect(() => {
        localStorage.removeItem('selectedappcode')
        localStorage.removeItem('pairurl')
        localStorage.removeItem('selectedCoin')
    }, [])

    const handlenav = (app) => {
        setisLoading(app)
        GetAppData(app.app_code).then(response => {
            let result = response.data
            if(result.terminals.length > 0){
                localStorage.setItem('userapp',JSON.stringify(result.terminals[0]))
                localStorage.setItem("web-logo", result.terminals[0].color_icon);
                localStorage.setItem("color-logo", result.terminals[0].color_icon);
            }
        })
        if(app.app_code !== ''){
            // let url = `/${app.app_code}/routing/btcinr`
            // setselectedurl(url)
            // navigate(url)
            localStorage.setItem('selectedappcode', app.app_code)
            handleOpenPopup(app.app_code)
        }
    }

    const handleOpenPopup = (appcode) => {
        let presult = []
        let obj = {
            app_code: localStorage.getItem('selectedappcode'),
            quote: Object.keys(coin).length > 0 ? coin.symbol : 'USDT'
        }
        GetpairsOfQuote(obj).then(response => {
            presult = response.data
            if(presult.status){
                setallpairs(presult.coins[0].pairs)
                if(Object.keys(presult).length > 0 && appcode !== ''){
                    GetAllPairs(appcode).then((response) => {
                        let result = response.data;
                        if(result.status) {
                            setpairsList(result.coins)
                            let coindtata = result.coins.filter(x => {
                                return(x.symbol === 'USDT')
                            })
                            if(coindtata[0].pairs !== undefined && coindtata[0].pairs.length >0){
                                setcoin(coindtata[0])
                                console.log(location)
                                console.log(localStorage.getItem('pairurl'))
                                // if(localStorage.getItem('pairurl') === `/select`) {
                                    let btcusdtpair = presult.coins[0].pairs.filter(x => {
                                        return(
                                            x.base === 'BTC'
                                        )
                                    })
                                    setselectedPair(btcusdtpair[0])
                                    let url = `/${localStorage.getItem('selectedappcode')}/routing/${btcusdtpair[0].base.toLowerCase()}${btcusdtpair[0].quote.toLowerCase()}`
                                    localStorage.setItem('pairurl', '/select')
                                    setisLoading({})
                                    setselectedurl(url)
                                    navigate(url)
                                // }
                            }
                        }
                    })
                }
            }
        })
        // let appcode = localStorage.getItem('selectedappcode')
       
    }

    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem("user"));
        let email = userDetails["user"].email;

        GetAllApps(email).then((response) => {
            let result =  response.data
            if(result.status){
                let appsList = response.data.terminals
                appsList.push(addtermial)
                setappSelectList(appsList)
                localStorage.setItem('appList', JSON.stringify(appsList))
            }
        })
    }, [])

  return (
    <div className='select-master'>
        <>
        {Object.keys(isLoading).length > 0 ?
            <div className='selectlogo'>
                <img className='selectlogoimg' src={isLoading.color_logo} alt=''/>
            </div>
        :
        <>
            <div className='selectmaincontainer'>
                <img className='selectmainlogo' src={selectmainlogo} alt='' />
                <p className='selectmaintext'>Which One Of Your Terminals Do You Want To Interact With?</p>
            </div>
            <div className='app-card'>
                <div className='selectappcontainer'>
                {appSelectList.map((each, id) => {
                    return(
                        <div className='select-app-card' key={id} onClick={() => handlenav(each)}>
                            <div className='select-app-img'>
                                {each.color_logo !== '' ?
                                <img className="select-app-img" src={each.color_logo} alt='' />
                                :''}
                            </div>
                            <>
                            {each.color_logo === addimg ? 
                            <p className='select-app-text'>{each.text}</p>
                            : ''}
                            </>
                        </div>
                    )
                })}
                </div>
            </div>
        </>
        }
        </>
    </div>
  )
}

export default SelectPage