import './App.css';
import MyRoutes from "./routes";

function App() {
  return (
    <MyRoutes />
  );
}

export default App;
