import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loginpage from "./pages/loginPage/loginPage";
import SelectPage from "./pages/selectPage/selectPage";
import TerminalRoutingPage from "./pages/terminalsroutingPage/terminalRoutingPage";
import React, { useEffect, useContext } from "react";
import { MainContext } from "../src/components/Context/context";
import { GetAllApps } from "./services/actionservice";

function MyRoutes() {
  const { selectedurl, setselectedurl, setappSelectList } =
    useContext(MainContext);

  const appps = () => {
    if (localStorage.getItem("user") !== null) {
      let userDetails = JSON.parse(localStorage.getItem("user"));
      let email = userDetails["user"].email;

      GetAllApps(email).then((response) => {
        let result = response.data;
        if (result.status) {
          let appsList = response.data.terminals;
          setappSelectList(appsList);
          localStorage.setItem("appList", JSON.stringify(appsList));
        }
      });
    }
  };

  useEffect(() => {
    console.log("zxczxc", selectedurl);
    appps();
    if (localStorage.getItem("appList") !== null) {
      const appsdata = JSON.parse(localStorage.getItem("appList"));
      const path = window.location.pathname.substring(1);
      const middle = path.substring(0, path.indexOf("/"));
      appsdata.forEach((each, id) => {
        if (each.app_code === middle) {
          setselectedurl(window.location.pathname);
        }
      });
      console.log(middle);
      // localStorage.setItem("selectedappcode", middle);
      // localStorage.setItem('pairurl', window.location.pathname)
    }
    // else{
    //   setselectedurl(window.location.pathname)
    // }

    // setselectedurl(window.location.pathname)
  }, [window.location.pathname]);
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/instacrypto" />} />
        <Route path="/:id" element={ <Loginpage />} /> */}
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<Loginpage />} />
        <Route path="/select" element={<SelectPage />} />
        <Route path="/terminal" element={<TerminalRoutingPage />} />
      </Routes>
    </Router>
  );
}

export default MyRoutes;
