import React, {useState, useContext, useEffect} from 'react';
import { MainContext } from "../../components/Context/context";
import './VaultToggle.scss';

function Toggle({id,dataInfo}) {
  const {check, setcheck,checkedName,setcheckedName,NameTabvault,setinfoFund,setinfoNonFund} = useContext(MainContext)
  console.log("vault_set",id)
  const toggle = (e) => {

     setcheckedName(e)
      switch (check) {
          case "off":
              setcheck('on')
              break;
          case "on":
            setcheck('off')
            break;
      } 
  }
 const handleChange=(e,g)=> {
    setcheckedName(e)
    console.log("dataInfo",g)
    if ( NameTabvault == "Personal Vault Sets" &&
    e !== "Main") 
      {
        // alert()
        setinfoNonFund(g)
      }
   else if(NameTabvault == "Fund Vault Sets" && e !== "Main") 
   {
    setinfoFund(g)
   }
    // const item = e.target.name;

    // const isChecked = e.target.checked;
    // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
   }

  console.log("checkedName")

  useEffect(()=>{
    
  },[])

  return (
    <div className='togglecomp'> 
        <div className='toggle-icon-part'>
          <input className='tgl1'  type="checkbox"  
          defaultChecked={id==checkedName&&true}
            checked={checkedName==id&&true} 
        onClick={()=>handleChange(id,dataInfo)}
        id={id}
        /><label className="tgl2" for={id}></label>
        </div>
        {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
    </div>
  )
}

export default Toggle;