import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import searchicon from "../../assets/images/navbar/searchicon.svg";
import searchicon1 from "../../assets/images/navbar/searchicon1.svg";
import usericon from "../../assets/images/navbar/usericon.svg";
import settingsIcon from "../../assets/images/navbar/settingsIcon.svg";
import fliter from "../../assets/images/fliter.svg";
import { MainContext } from "../Context/context";
import "./navbar.scss";
import market from "../../assets/images/navbar/market.svg";
import routing from "../../assets/images/navbar/routing.svg";
import vaults from "../../assets/images/navbar/vaults.svg";
import support from "../../assets/images/navbar/support.svg";
import market1 from "../../assets/images/navbar/market1.svg";
import routing1 from "../../assets/images/navbar/routing1.svg";
import vaults1 from "../../assets/images/navbar/vaults1.svg";
import support1 from "../../assets/images/navbar/support1.svg";
import aipro from "../../assets/images/xp.svg";
import term from "../../assets/images/term.svg"
const navMenu = [
  {
    id: 4,
    menuItem: "Terminal",
    menuIcons: term,
    darkIcon: term,
  },
  {
    id: 0,
    menuItem: "Market",
    menuIcons: market,
    darkIcon: market1,
  },
  {
    id: 1,
    menuItem: "Routing",
    menuIcons: routing,
    darkIcon: routing1,
  },
  {
    id: 2,
    menuItem: "Vaults",
    menuIcons: vaults,
    darkIcon: vaults1,
  },
  {
    id: 3,
    menuItem: "Support",
    menuIcons: support,
    darkIcon: support1,
  },
];

const Navbar = () => {
  let navigate = useNavigate();
  const {
    selectedNav,
    setselectedNav,
    selectedapp,
    setselectedapp,
    openpairs,
    setopenpairs,
    setopenrouting,infoNonFund,
    openuserProfile,infoFund,
    checkedName,
    setopenuserProfile,NameTabvault,
  } = useContext(MainContext);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userImg =
    userDetails !== null ? userDetails.user.profile_img : usericon;
    let mail=JSON.parse(localStorage.getItem("user"))["user"].email;
  const [navsearchterm, setnavsearchterm] = useState("");
console.log("infoNonFund",infoNonFund)
  useEffect(() => {
    let appcode = localStorage.getItem("selectedappcode");
    // let appList = JSON.parse(localStorage.getItem('appList'))
    // appList.forEach((each, id) => {
    //     if(each.app_code === appcode){
    //         setselectedapp(each)
    //     }
    // })

    setselectedapp(JSON.parse(localStorage.getItem("userapp")));
  }, [localStorage.getItem("selectedappcode")]);

  const handleSelectNav = async(each) => {
    setopenpairs(false);
    setopenuserProfile(false);
    if (each.menuItem !== "Support") {
      setopenrouting(each.menuItem);
     
      if (selectedNav === "Vaults") {
        await setselectedNav(each.menuItem);
      }
      if (selectedNav === each.menuItem) {
        setselectedNav("Masters");
      } else {
     
          await setselectedNav(each.menuItem);
         
     
      }
    }
  };

  const handleopenpairs = () => {
    setopenpairs(!openpairs);
    setselectedNav("Masters");
    setopenuserProfile(false);
  };

  const handleselectProfile = () => {
    setopenpairs(false);
    setopenuserProfile(!openuserProfile);
  };

  return (
    <div
      className="navbar-master"
      style={{
        backgroundColor: JSON.parse(localStorage.getItem("light"))
          ? ""
          : "#17181F",
        color: JSON.parse(localStorage.getItem("light")) ? "" : "#E7E7E7",
        borderColor: JSON.parse(localStorage.getItem("light")) ? "" : "#5F6163",
      }}
    >
      <div className="nav-logo-section">
        <div
          className="nav-logo-box"
          onClick={() => navigate("/terminal")}
          style={{
            backgroundColor: JSON.parse(localStorage.getItem("light"))
              ? ""
              : "#17181F",
          }}
        >
          <img className="nav-logo" src={aipro} alt="" />
        </div>
        <div
          className="nav-search-menu"
          style={{
            backgroundColor: JSON.parse(localStorage.getItem("light"))
              ? ""
              : "#17181F",
            borderColor: JSON.parse(localStorage.getItem("light"))
              ? ""
              : "#5F6163",
          }}
        >
          <div
            className="nav-search-box"
            style={{
              backgroundColor: JSON.parse(localStorage.getItem("light"))
                ? ""
                : "#17181F",
            }}
            onClick={() => handleopenpairs()}
          >
            <img
              className="search-nav-img"
              src={
                JSON.parse(localStorage.getItem("light"))
                  ? searchicon
                  : searchicon1
              }
              alt=""
            />
            <p
              className="search-nav-text"
              style={{
                color: JSON.parse(localStorage.getItem("light"))
                  ? ""
                  : "#E7E7E7",
              }}
            >
              Search For Trading Pairs...
            </p>
          </div>
        </div>
      </div>
      <div className="nav-menu-box">
        {navMenu.map((each, id) => {
          return (
            <div
              className="nav-menuitem"
              key={id}
              style={{ opacity: selectedNav === each.menuItem ? 1 : "" }}
              onClick={() => handleSelectNav(each)}
            >
              <img
                className="nav-menu-img"
                src={
                  JSON.parse(localStorage.getItem("light"))
                    ? each.menuIcons
                    : each.darkIcon
                }
                alt=""
              />
              <p
                className="nav-menu-text"
                style={{
                  color: JSON.parse(localStorage.getItem("light"))
                    ? ""
                    : "#E7E7E7",
                }}
              >
                {each.menuItem}
              </p>
            </div>
          );
        })}
     
     {
      checkedName=="Main"?
      <div className="nav-menu-actionLast" >
      <div className="nav-action-menuItem1" key="user">
        <img
          className="user-icon"
          src={userImg !== "" ? userImg : usericon}
          alt=""
        />
           </div>
        <div className="NameInfoAI">
          <div className="InfovaultSelect">{checkedName}</div>
          <div className="valutid">{mail}</div>
        </div>
        <div className="nav-menu-actionLast">
        <img className="fliterlogout" src={fliter} onClick={() => handleselectProfile()}/>
        </div>
      </div>
      :NameTabvault == "Fund Vault Sets" && checkedName!=="Main"?

      <div className="nav-menu-actionLast" >
      <div className="nav-action-menuItem1" key="user">
        <img
          className="user-icon"
          src={ infoFund !== "" ? infoFund?.vault_set_icon : usericon}
          alt=""
        />
           </div>
        <div className="NameInfoAI">
          <div className="InfovaultSelect">{infoFund?.nick_name}</div>
          <div className="valutid">{checkedName}</div>
        </div>
      <div className="nav-menu-actionLast" >
        <img className="fliterlogout" src={fliter}onClick={() => handleselectProfile()} />
      </div>
      </div>
      :
      <div className="nav-menu-actionLast" >
      <div className="nav-action-menuItem1" key="user">
        <img
          className="user-icon"
          src={ infoNonFund !== "" ? infoNonFund?.vaultsetIcon : usericon}
          alt=""
        />
           </div>
        <div className="NameInfoAI">
          <div className="InfovaultSelect">{infoNonFund?.nickName}</div>
          <div className="valutid">{checkedName}</div>
        </div>
      <div className="nav-menu-actionLast" >
        <img className="fliterlogout" src={fliter}onClick={() => handleselectProfile()} />
      </div>
      </div>
     }

        </div> 

      
    </div>
  );
};

export default Navbar;
