import axios from 'axios';  


///// app data (Get Terminals Data For One Terminal)

export const GetAppData = (appcode) => {
  try{
    const response = axios.get(`https://comms.globalxchange.io/coin/terminal/app/get`, 
    {
      params: {
        app_code: appcode
      }
    }
    );
    return response;
  }catch(error){
    return error.response
  }
}




//// login service
export const Loginservice =  async (object) => {
      try{
        const response = await axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, object);
        return response;
      }catch(error){
        return error.response
      }
}