import React, {useState, useContext, useEffect} from 'react';
import { MainContext } from '../Context/context';
import './toggle.scss';

function Toggle() {
  const {check, setcheck} = useContext(MainContext)
  const toggle = () => {
      switch (check) {
          case "off":
              setcheck('on')
              break;
          case "on":
            setcheck('off')
            break;
      }
  }

  return (
    <div className='togglecomp'>
        <div className='toggle-icon-part'>
          <input className='tgl1' type="checkbox" id="switch" onClick={(e) => toggle()}/><label className="tgl2" for="switch"></label>
        </div>
        {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
    </div>
  )
}

export default Toggle;